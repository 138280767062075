import { useScroll } from "react-use";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useScroller = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const { y, x } = useScroll(containerRef);
  const scrollHeight =
    (containerRef &&
      containerRef.current &&
      containerRef.current.scrollHeight) ||
    0;
  const clientHeight =
    (containerRef &&
      containerRef.current &&
      containerRef.current.clientHeight) ||
    0;
  const isNotBottom = scrollHeight - y - clientHeight > 40;
  const isTop = y === 0;

  const ScrollToBottom = (): void => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return {
    ScrollToBottom,
    isNotBottom,
    isTop,
    x,
    y,
  };
};

export default useScroller;

import { useContext } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ScrollToBottom, {
  useScrollToBottom,
  useSticky,
} from "react-scroll-to-bottom";
import { styled } from "@mui/material/styles";
import { map, filter } from "lodash";
import { nanoid } from "nanoid";

import Typography from "components/Typography";
import { ChatContext } from "contexts/ChatProvider";

import MessageBox from "../MessageBox";
import Typing from "../Typing";
import Finding from "../Finding";

const MessageContainer = styled(ScrollToBottom)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  minHeight: "0px",
  padding: theme.spacing(1),
  "& > div": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    "&::-webkit-scrollbar": {
      width: 6,
      "&-thumb": {
        background: "#D7E0E7",
        borderRadius: 8,
      },
    },
  },
  "& button.default-sticky": {
    display: "none",
  },
}));

const BoxCenter = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const Messages = (): React.ReactElement => {
  const {
    state: {
      messages,
      identity,
      isFinding,
      isEndChat,
      socket,
      typing,
      departmentName,
    },
  } = useContext(ChatContext);
  const [sticky] = useSticky();
  const scrollToBottom = useScrollToBottom();
  const botMessages = filter(messages, ({ isAgent }) => !isAgent) || [];
  const agentMessages = filter(messages, ({ isAgent }) => !!isAgent) || [];
  const getAgent = (): string => {
    switch (departmentName) {
      case "PRC":
        return "PRC Admin";
      case "CARE":
        return "CARE Partner";
      default:
        return "";
    }
  };

  return (
    <>
      {botMessages.length > 0 &&
        map(
          botMessages,
          (
            {
              user,
              message,
              datetime,
              bot,
              isAgent,
              attachments,
              type,
              participantId,
            },
            index
          ) => (
            <MessageBox
              key={nanoid()}
              isSender={participantId === identity?.conversation.participantId}
              user={user}
              datetime={datetime}
              bot={bot}
              isLast={!!(botMessages.length !== index + 1)}
              isAgent={isAgent}
              attachments={attachments}
              type={type}
            >
              {message}
            </MessageBox>
          )
        )}
      {!isEndChat && !!identity?.isAgent && !isFinding && (
        <BoxCenter mt="auto">
          <Typography
            textAlign="center"
            color="primary"
            fontWeight="fontWeightBold"
          >
            - - Chat started with Live Chat - -
          </Typography>
        </BoxCenter>
      )}
      {agentMessages &&
        agentMessages.length > 0 &&
        map(
          agentMessages,
          (
            {
              user,
              message,
              datetime,
              bot,
              isAgent,
              attachments,
              type,
              participantId,
            },
            index
          ) => (
            <MessageBox
              key={nanoid()}
              isSender={participantId === identity?.conversation.participantId}
              user={user}
              datetime={datetime}
              bot={bot}
              isLast={!!(agentMessages.length !== index + 1)}
              isAgent={isAgent}
              attachments={attachments}
              type={type}
            >
              {message}
            </MessageBox>
          )
        )}
      {isEndChat && (
        <BoxCenter mt="auto">
          <Typography
            textAlign="center"
            color="neutral.semiLight"
            variant="body2"
          >
            End of conversation
          </Typography>
          <Typography
            textAlign="center"
            color="neutral.semiLight"
            variant="body2"
          >
            Thank you for using our live chat
          </Typography>
        </BoxCenter>
      )}
      {!socket.connected && (
        <BoxCenter mt="auto">
          <Typography textAlign="center" color="error" variant="body2">
            We are unable to connect you to the chat service. Please try again
            later.
          </Typography>
        </BoxCenter>
      )}
      {!!typing?.status && <Typing />}
      {!sticky && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="end"
          position="absolute"
          bottom={0}
          right={20}
        >
          <IconButton
            sx={{
              height: "40px",
              width: "40px",
              bgcolor: "neutral.extraLight",
              "&:hover": {
                bgcolor: "neutral.extraLight",
              },
            }}
            onClick={() => scrollToBottom()}
          >
            <KeyboardArrowDownIcon fontSize="inherit" />
          </IconButton>
        </Box>
      )}
      {!isEndChat && <Finding isLoading={isFinding} agent={getAgent()} />}
    </>
  );
};

const ChatBody = (props: { children: React.ReactNode }): React.ReactElement => {
  const { children } = props;
  return (
    <MessageContainer followButtonClassName="default-sticky">
      {children}
      <Messages />
    </MessageContainer>
  );
};

export default ChatBody;

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
}));

const Container = styled(Box)(({ theme }) => ({
  height: "calc(100% - 0px)",
  width: "calc(100% - 0px)",
  margin: "0px",
  boxShadow: "4px 4px 8px rgba(7,7,7, 0.025104)",
  overflow: "hidden",
  backgroundColor: "rgb(255, 255, 255)",
  maxWidth: "600px",
  maxHeight: "95%",
  borderRadius: theme.spacing(1),
  position: "relative",
  [`${theme.breakpoints.down("sm")}`]: {
    maxHeight: "100%",
  },
}));

const ChatAbsolute = styled(Box)(() => ({
  position: "absolute",
  top: "0px",
  left: "0px",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

const ChatContainer = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { children } = props;
  return (
    <Main>
      <Container>
        <ChatAbsolute>{children}</ChatAbsolute>
      </Container>
    </Main>
  );
};

export default ChatContainer;

import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
// import CardMedia from "@mui/material/CardMedia";
import { split, last } from "lodash";
import { CardMedia } from "@mui/material";

interface FileTypeProps {
  file?: File;
  filename: string;
  originalFilename?: string;
}

const FileType = (props: FileTypeProps): React.ReactElement => {
  const { file, filename, originalFilename } = props;
  const link = (file ? URL.createObjectURL(file) : filename) as string;
  const fileType = last(split(originalFilename, ".")) as string;
  const imageTypes = ["gif", "jpeg", "png", "jpg"];

  const handleDownload = (): void => {
    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", link);
    // linkElement.setAttribute("target", "_blank");
    if (originalFilename)
      linkElement.setAttribute("download", originalFilename);
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  };

  if (imageTypes.includes(fileType))
    return (
      <Tooltip title={`Download ${originalFilename}.`} arrow>
        <CardMedia
          width={200}
          height={200}
          draggable={false}
          component="img"
          sx={{ borderRadius: 3, cursor: "pointer" }}
          alt={originalFilename}
          image={link}
          onClick={handleDownload}
        />
      </Tooltip>
    );

  return (
    <Box display="flex" gap={1} sx={{ wordBreak: "break-word" }} width="100%">
      <InsertDriveFileIcon />
      <Tooltip title={`Download ${originalFilename}.`} arrow>
        <Link
          color="inherit"
          href={link}
          underline="hover"
          download={originalFilename}
        >
          {originalFilename}
        </Link>
      </Tooltip>
    </Box>
  );
};

export default FileType;

import InputAdornment from "@mui/material/InputAdornment";
import { FieldPath, useFormContext } from "react-hook-form";
import { map, toInteger } from "lodash";

import Form from "components/Form";
import { FormControl } from "contexts/ChatProvider";

interface InputTypesProps extends FormControl {
  name: string;
}

interface AnswerBody {
  [x: string]: string | number;
}

const InputTypes = (props: InputTypesProps): React.ReactElement | null => {
  const { type, name, text, values } = props;
  const { setValue, trigger } = useFormContext();

  const handleMobileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name: controlName, value } = event.target;
    const newValue = value.replace(/\D/g, "").substring(0, 10);
    setValue(controlName as FieldPath<AnswerBody>, newValue);
    trigger(controlName as FieldPath<AnswerBody>);
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name: controlName, value } = event.target;
    const newValue = Math.min(toInteger(value.replace(/\D/g, "")), 99);
    setValue(controlName as FieldPath<AnswerBody>, newValue);
    trigger(controlName as FieldPath<AnswerBody>);
  };

  switch (type) {
    case "TEXT_FIELD":
    case "EMAIL":
      return (
        <Form.TextField
          key={crypto.randomUUID()}
          name={name}
          label={text}
          placeholder={text}
        />
      );
    case "SELECT": {
      const controlValues =
        map(values, (value) => ({
          value,
          label: value,
        })) || [];
      return (
        <Form.Select
          key={crypto.randomUUID()}
          name={name}
          label={text}
          options={controlValues}
          optionValueKey="value"
          optionLabelKey="label"
        />
      );
    }
    case "NUMBER":
      return (
        <Form.TextField
          key={crypto.randomUUID()}
          name={name}
          label={text}
          placeholder={text}
          onChange={handleNumberChange}
        />
      );
    case "MOBILE":
      return (
        <Form.TextField
          key={crypto.randomUUID()}
          name={name}
          label={text}
          placeholder=""
          InputProps={{
            type: "mobile",
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="end">+63&nbsp;</InputAdornment>
            ),
          }}
          onChange={handleMobileChange}
        />
      );
    default:
      return null;
  }
};

export default InputTypes;

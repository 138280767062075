import { useState } from "react";

import Box from "@mui/material/Box";
import MuiPaper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import { useDebounce } from "react-use";
import { styled } from "@mui/material/styles";

interface FindingProps {
  isLoading?: boolean;
  agent: string;
}

const Paper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: 7,
  boxShadow: "0px 2px 6px rgba(0,0,0, 0.154939)",
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(0.5, 4),
}));

const Finding = (props: FindingProps): React.ReactElement | null => {
  const { isLoading, agent } = props;
  const debounceSec = isLoading ? 0 : 3000;
  const [isHide, setHide] = useState<boolean | undefined>(true);

  useDebounce(
    () => {
      setHide(!isLoading);
    },
    debounceSec,
    [isLoading]
  );

  if (isHide) return null;

  return (
    <Fade in={!isHide}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="sticky"
        bottom={0}
      >
        <Paper sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {isLoading ? (
            <>
              <CircularProgress size={15} /> Hintayin lamang ang sagot ng{" "}
              {agent}.
            </>
          ) : (
            "Sa Wakas! Narito na ang inyong Agent.."
          )}
        </Paper>
      </Box>
    </Fade>
  );
};

Finding.defaultProps = {
  isLoading: false,
};

export default Finding;

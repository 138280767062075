import Box from "@mui/material/Box";
import { styled, keyframes } from "@mui/material/styles";

const typingEffect = keyframes`
  0% {
    transform: translateY(0px)
  }
  28% {
    transform: translateY(-7px)
  }
  44% {
    transform: translateY(0px)
}`;

const Bubble = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.neutral.extraLight,
  borderRadius: theme.spacing(0, 1.25, 1.25),
  display: "flex",
  alignItems: "center",
  gap: 5,
  "& div:nth-of-type(1)": {
    animationDelay: "200ms",
  },
  "& div:nth-of-type(2)": {
    animationDelay: "300ms",
  },
  "& div:nth-of-type(3)": {
    animationDelay: "400ms",
  },
}));

const Dot = styled(Box)(({ theme }) => ({
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: theme.palette.common.black,
  animation: `${typingEffect} 1.8s infinite ease-in-out`,
}));

const Typing = (): React.ReactElement => {
  return (
    <Box display="flex" gap={1} mt="auto">
      <Bubble>
        <Dot />
        <Dot />
        <Dot />
      </Bubble>
    </Box>
  );
};

export default Typing;

// import type { Message } from "contexts/ChatProvider";
import { useContext } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { map, filter, toString } from "lodash";

import {
  ChatContext,
  MessageType,
  FormControlType,
  FormControl,
} from "contexts/ChatProvider";

interface ButtonQuestionProps {
  type: FormControlType;
  controls: FormControl[];
  isLast?: boolean;
}

const ButtonQuestion = (props: ButtonQuestionProps): React.ReactElement => {
  const { type, controls, isLast } = props;
  const {
    state: { socket, identity, isEndChat },
    dispatch,
  } = useContext(ChatContext);
  const participantId = identity?.conversation.participantId as number;
  const room = identity?.conversation.uid as string;
  const isAgent = !!identity?.isAgent;
  const sessionToken = identity?.chatSessionToken as string;

  const sendMessage = async (
    goTo: string,
    message: string,
    isEnd?: boolean
  ): Promise<void> => {
    const messageData = {
      room,
      isAgent,
      participantId,
      datetime: new Date(),
      type: "TEXT" as MessageType,
      bot: {
        request: {
          goTo,
          sessionToken,
        },
      },
      message,
      isEnd,
    };

    if (!identity) return;

    try {
      await socket.emit("send_message", messageData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      dispatch({
        type: "SET_MESSAGE",
        payload: { message: { ...messageData, isSender: true } },
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={type === "BUTTON_GROUP" ? "row" : "column"}
      alignItems={type === "BUTTON_GROUP" ? "start" : "center"}
      gap={1}
    >
      {map(
        filter(controls, (control) => control.type === "BUTTON"),
        (control) => (
          <Button
            key={crypto.randomUUID()}
            onClick={() => {
              const values = control.values ?? [];

              if (values.length > 0)
                sendMessage(control.goTo, toString(values[0]), control.isEnd);
            }}
            variant="outlined"
            sx={{
              borderRadius: 3,
              width: type === "BUTTON_GROUP" ? "auto" : 300,
            }}
            disabled={isLast || isEndChat}
          >
            {control.text}
          </Button>
        )
      )}
    </Box>
  );
};

export default ButtonQuestion;

import { createTheme, alpha } from "@mui/material/styles";

type Form = {
  main: string;
};

type Light = {
  light: string;
  main: string;
};

declare module "@mui/material/styles" {
  interface Theme {
    drawerWidth: number;
  }
  interface ThemeOptions {
    drawerWidth: number;
  }
  interface Palette {
    form: Form;
    primaryLight: Light;
    secondaryLight: Light;
    successLight: Light;
    warningLight: Light;
    errorLight: Light;

    neutral: Palette["primary"] & {
      extraLight?: string;
      semiLight?: string;
    };
    drawer: {
      backgroundColor: string;
      borderColor: string;
    };
  }
  interface PaletteOptions {
    form: Form;
    primaryLight: Light;
    secondaryLight: Light;
    successLight: Light;
    warningLight: Light;
    errorLight: Light;
    extraLight?: string;
    semiLight?: string;
    neutral?: PaletteOptions["primary"];
    drawer: {
      backgroundColor: string;
      borderColor: string;
    };
  }
  interface SimplePaletteColorOptions {
    extraLight?: string;
    semiLight?: string;
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const theme = createTheme({
  drawerWidth: 240.5,
  palette: {
    neutral: {
      extraLight: "#EEF3F7", // 50
      semiLight: "#D1D6DB", // 100
      light: "#838F9D", // 300
      main: "#444B54", // 500
    },
    primaryLight: {
      light: "#E5F8FC", // 50
      main: "#92DEF9", // 100
    },
    primary: {
      light: "#4EC2EA", // 300
      main: "#00C0E5", // 500
      dark: "#02678A", // 900
    },
    secondaryLight: {
      light: "#FDE1D2", // 50
      main: "#FABA96", // 100
    },
    secondary: {
      light: "#FF8B4A", // 300
      main: "#FB6D1D", // 500
      dark: "#BC4909", // 900
    },
    successLight: {
      light: "#E5FCF2", // 50
      main: "#CAFFE8", // 100
    },
    success: {
      light: "#83E1B9", // 300
      main: "#5BBC93", // 500
      dark: "#16553A", // 900
    },
    warningLight: {
      light: "#FEE6D7", // 50
      main: "#F3A678", // 100
    },
    warning: {
      light: "#F3A678", // 300
      main: "#E17D41", // 500
      dark: "#E17D41", // 900
    },
    errorLight: {
      light: "#FFDADA", // 50
      main: "#FF9E9E", // 100
    },
    error: {
      light: "#EF5D5D", // 300
      main: "#FD2626", // 500
      dark: "#6C0000", // 900
    },
    background: {
      default: "#FBFBFB",
    },
    common: {
      black: "#000000",
      white: "#FFF",
    },
    form: {
      main: "#E5E5E5",
    },
    drawer: {
      backgroundColor: "#F3F7FF",
      borderColor: "#D1D6DB",
    },
  },
  typography: {
    fontFamily: "Inter",
    body3: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: 0,
    },
  },
  shape: {
    borderRadius: 4,
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
});

export default createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        src: url("/fonts/Inter-Bold.ttf") format("opentype");
      }

      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        src: url("/fonts/Inter-Medium.ttf") format("opentype");
      }

      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        src: url("/fonts/Inter-Medium.ttf") format("opentype");
      }

      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        src: url("/fonts/Inter-Regular.ttf") format("opentype");
      }
    `,
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          color: theme.palette.primary.main,
        },
        standardError: {
          color: theme.palette.error.main,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiDivider: {
      root: {
        color: "#F9F9FB",
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightMedium,
          "&.Mui-disabled": {
            color: theme.palette.common.black,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(0, 1.5px) scale(1)",
        },
        root: {
          "&.Mui-focused": {
            color: theme.palette.common.black,
          },
          fontSize: theme.typography.pxToRem(14),
          top: "6px",
        },
      },
      defaultProps: {
        shrink: true,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: theme.palette.common.black,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px !important",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        filled: {
          paddingRight: "0px !important",
        },
        root: {
          marginTop: "0px !important",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          paddingRight: "0px !important",
          borderRadius: 3,
          border: "1px solid",
          borderColor: alpha(theme.palette.neutral.light, 0.5),
          backgroundColor: theme.palette.common.white,
          "&:hover:not($disabled)": {
            borderColor: theme.palette.neutral.light,
          },
          "&:hover": {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
          },
          "& > svg": {
            color: theme.palette.neutral.main,
          },
          "&.Mui-focused": {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.common.white,
          },
          "&.Mui-error": {
            borderColor: theme.palette.error.main,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.neutral.extraLight,
            color: theme.palette.neutral.main,
          },
        },
        input: {
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(1.25, 1.5),
          "&::placeholder": {
            color: alpha(theme.palette.common.black, 0.75),
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          border: "1px solid",
          borderColor: alpha(theme.palette.neutral.light, 0.5),
          backgroundColor: theme.palette.common.white,
          "&:hover:not($disabled)": {
            borderColor: theme.palette.neutral.light,
          },
          "&:hover": {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
          },
          "& > svg": {
            color: theme.palette.neutral.main,
          },
          "&.Mui-focused": {
            borderColor: theme.palette.primary.dark,
          },
          "&.Mui-error": {
            borderColor: theme.palette.error.main,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.neutral.extraLight,
            color: theme.palette.neutral.main,
          },
        },
        adornedStart: {
          paddingLeft: theme.spacing(1.5, 0.5),
        },
        adornedEnd: {
          paddingRight: theme.spacing(1.5, 0.5),
        },
        // adornedStart: {
        //   paddingLeft: "0px !important",
        //   paddingRight: "0px !important",
        // },
        // adornedEnd: {
        //   paddingLeft: "0px !important",
        //   paddingRight: "0px !important",
        // },
        input: {
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(1, 1.5),
          "&::placeholder": {
            color: alpha(theme.palette.common.black, 0.75),
          },
          "&.MuiInputBase-inputAdornedStart, &.MuiInputBase-inputAdornedEnd": {
            padding: theme.spacing(1, 0),
          },
        },
        formControl: {
          "label + &": {
            marginTop: "30px !important",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        margin: "none",
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          select: {
            backgroundColor: theme.palette.common.white,
          },
        },
        icon: {
          color: theme.palette.form.main,
          right: "0.75rem",
          position: "absolute",
          userSelect: "none",
          pointerEvents: "none",
        },
      },
      defaultProps: {
        variant: "standard",
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          // getContentAnchorEl: null,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: `0 2px 15px 5px ${alpha("#464646", 0.1)}`,
          overflow: "initial",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: theme.spacing(0.75, 1.5),
        },
        tag: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightBold,
        },
        containedPrimary: {
          color: theme.palette.common.white,
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
          "&:focus": {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        outlinedPrimary: {
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
          "&:focus": {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        },
        textPrimary: {
          "&:hover": {
            backgroundColor: theme.palette.primaryLight.light,
          },
          "&:focus": {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
        },
      },
      defaultProps: {
        variant: "contained",
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialog-paper": {
            borderRadius: theme.spacing(2),
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "&>:not(:first-of-type)": {
            marginLeft: theme.spacing(2),
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

import CryptoJS from "crypto-js";

const ENC_KEY = process.env.REACT_APP_ENC_KEY;
const ENC_IV = process.env.REACT_APP_ENC_IV;

const decrypt = (ciphertext: string): string => {
  const keyBytes = CryptoJS.enc.Utf8.parse(ENC_KEY);
  const ivBytes = CryptoJS.enc.Utf8.parse(ENC_IV);

  try {
    // Decrypt the ciphertext using AES-256-CBC
    const decrypted = CryptoJS.AES.decrypt(ciphertext, keyBytes, {
      iv: ivBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // Convert the decrypted data to a UTF-8 string
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  } catch (e) {
    return "Cannot load text.";
  }
};

export default decrypt;

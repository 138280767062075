import { reduce, replace } from "lodash";

/*
  SEARCH OPTIONS
  * eq - Equal
  * neq - Not Equal
  * in - Not In
  * gt - Greater Than
  * gte - Greater Than or Equal
  * lt - Less Than
  * lte - Less Than or Equal
  * btwn - Between
*/
type FilterType =
  | "start"
  | "end"
  | "like"
  | "eq"
  | "neq"
  | "in"
  | "nin"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "btwn"
  | "enums_like";

const formatFilters = (
  filter: Record<string, any>,
  type: FilterType
): Record<string, any> => {
  if (type === "enums_like") {
    return reduce(
      filter,
      (result, value, key) => ({
        ...result,
        ...(value && {
          [key]: `like:${replace(replace(value, "&", "and"), " ", "_")}`,
        }),
      }),
      {}
    );
  }

  return reduce(
    filter,
    (result, value, key) => ({
      ...result,
      ...(value && { [key]: `${type}:${value}` }),
    }),
    {}
  );
};

export default formatFilters;

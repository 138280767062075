/* eslint-disable no-param-reassign */
import axios from "axios";
import queryString from "query-string";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT_ID;
const X_CLIENT_SECRET = process.env.REACT_APP_X_CLIENT_SECRET;
const X_APP_ID = process.env.REACT_APP_X_APP_ID;

const request = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "x-client-id": X_CLIENT_ID as string,
    "x-client-secret": X_CLIENT_SECRET as string,
    "x-app-id": X_APP_ID as string,
  },
  paramsSerializer: {
    encode: (params) =>
      queryString.stringify(params, {
        skipEmptyString: true,
        skipNull: true,
      }),
  },
});

request.interceptors.request.use(
  (config) => config,
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return Promise.reject(error.response.data.error);
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: "The request was made but no response was received",
      });
    }
    // Something happened in setting up the request that triggered an Error
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      message: "The request was made but no response was received",
    } as any);
  }
);

export default request;

import type { AnySchema } from "yup";

import googlePhoneNumber from "google-libphonenumber";
import { isEmpty, reduce, map } from "lodash";
import moment from "moment";
import * as yup from "yup";

import { getFileSize } from "helpers";

const phoneUtil = googlePhoneNumber.PhoneNumberUtil.getInstance();
const today = moment();

yup.setLocale({
  mixed: {
    required: "This field is required",
  },
  string: {
    email: "Invalid Email Address",
  },
  number: {
    min: "This field is required",
  },
  array: {
    min: "This field is required",
  },
});

yup.addMethod<AnySchema>(
  yup.string,
  "dateFormat",
  function dateFormat(format = "YYYY-MM-DD", message = "Invalid Date") {
    return this.test({
      name: "dateFormat",
      exclusive: true,
      message,
      test: (value) => isEmpty(value) || moment(value, format, true).isValid(),
    }).nullable();
  }
);

yup.addMethod<AnySchema>(
  yup.string,
  "timeFormat",
  function timeFormat(
    message = 'Invalid time, time format should be "HH:MM A/P" (eg. 08:00 A)'
  ) {
    return this.test({
      name: "timeFormat",
      exclusive: true,
      message,
      test: (value) =>
        isEmpty(value) ||
        moment(moment(value).format("hh:mm"), "hh:mm", true).isValid(),
    }).nullable();
  }
);

yup.addMethod<AnySchema>(yup.string, "legalAge", function legalAge() {
  return this.test({
    name: "legalAge",
    exclusive: true,
    message: "You must be 15 years old or above",
    test: (value) =>
      isEmpty(value) ||
      moment(value).isBefore(moment(moment()).subtract(15, "years")),
  });
});

yup.addMethod<AnySchema>(yup.string, "phone", function phone() {
  return this.test({
    name: "phone",
    exclusive: true,
    message: "Invalid Mobile Number",
    test: (value) => {
      try {
        return (
          isEmpty(value) ||
          (phoneUtil.isValidNumber(phoneUtil.parse(value, "PH")) &&
            /(\+?\d{2}?\s?\d{3}\s?\d{3}\s?\d{4})|([0]\d{3}\s?\d{3}\s?\d{4})/g.test(
              value.replace(/^/, "63")
            ))
        );
      } catch (e) {
        return false;
      }
    },
  });
});

yup.addMethod<AnySchema>(yup.string, "uic", function uic() {
  return this.test({
    name: "uic",
    exclusive: true,
    message: "Invalid UIC",
    test: (value) => {
      return isEmpty(value) || /^[A-Za-z]{4}\d{10}$/g.test(value);
    },
  });
});

yup.addMethod<AnySchema>(yup.mixed, "maxFileNo", function maxFileNo(number) {
  return this.test({
    message: `Maximum of ${number} file is allowed.`,
    test: (values) => {
      return isEmpty(values) || values.length <= number;
    },
  });
});

yup.addMethod<AnySchema>(yup.mixed, "maxFileSize", function maxFileSize(size) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const megabyteSize = megabyte * size;

  return this.test({
    message: `The file is too large. Maximum of ${getFileSize(
      megabyteSize
    )} only.`,
    test: (values) => {
      const sizes = map(values, "size");
      const totalFileSize = reduce(sizes, (acc, item) => acc + item);

      return isEmpty(values) || totalFileSize <= megabyteSize;
    },
  });
});

yup.addMethod<AnySchema>(yup.string, "legalAge", function legalAge() {
  return this.test({
    name: "legalAge",
    exclusive: true,
    message: "You must be 15 years old or above",
    test: (value) =>
      isEmpty(value) ||
      moment(value).isBefore(moment(today).subtract(15, "years")),
  });
});

export default yup;

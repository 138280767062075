import type { SelectProps } from "./types";
import type { SvgIconProps } from "@mui/material/SvgIcon";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { keyBy, toString } from "lodash";
import { useController } from "react-hook-form";
import { styled } from "@mui/material/styles";

import { useMetaError } from "hooks";
import { getOption } from "helpers";

const ErroTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}));

const Select = (props: SelectProps): React.ReactElement => {
  const {
    name,
    label,
    placeholder,
    defaultValue,
    options,
    optionValueKey,
    optionLabelKey,
    optionIconKey,
    ...rest
  } = props;

  const {
    field: { ref, value, ...field },
    fieldState,
  } = useController({ name, defaultValue });
  const { hasError, errorMessage } = useMetaError(fieldState);

  const getOptionValue = getOption(optionValueKey);
  const getOptionLabel = getOption(optionLabelKey);
  const getOptionIcon = getOption(optionIconKey);

  const renderSelectIcon = (
    selectIconProps: SvgIconProps
  ): React.ReactElement => <ArrowDropDownIcon {...selectIconProps} />;

  const renderSelectValue = (selected: string): React.ReactElement => {
    if (!selected || !selected.length) {
      return (
        <Typography noWrap color="#C7C7C7">
          {placeholder}
        </Typography>
      );
    }

    return (
      <Box display="flex" alignItems="center" gap={1}>
        {getOptionIcon(keyBy(options, getOptionValue)[selected]) &&
          getOptionIcon(keyBy(options, getOptionValue)[selected])}
        <Typography noWrap>
          {getOptionLabel(keyBy(options, getOptionValue)[selected])}
        </Typography>
      </Box>
    );
  };

  return (
    <ErroTooltip
      title={errorMessage || ""}
      arrow
      placement="right"
      disableHoverListener={!hasError}
      disableFocusListener={!hasError}
      disableInteractive={!hasError}
      disableTouchListener={!hasError}
    >
      <FormControl error={hasError} fullWidth>
        <InputLabel id={name}>{label}</InputLabel>
        <MuiSelect
          {...field}
          {...rest}
          value={toString(value)}
          inputRef={ref}
          id={name}
          labelId={name}
          displayEmpty
          IconComponent={renderSelectIcon}
          renderValue={renderSelectValue}
          disableUnderline
        >
          {/* <MenuItem value="">None</MenuItem> */}
          {options.map((option) => (
            <MenuItem
              key={getOptionValue(option)}
              value={getOptionValue(option)}
            >
              {getOptionIcon(option) && (
                <ListItemIcon>{getOptionIcon(option)}</ListItemIcon>
              )}
              {getOptionLabel(option)}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </ErroTooltip>
  );
};

Select.defaultProps = {
  label: "",
  name: "",
  defaultValue: "",
  placeholder: "Please Select",
  options: [],
  optionValueKey: "value",
  optionLabelKey: "label",
};

export default Select;

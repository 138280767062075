/* eslint-disable unused-imports/no-unused-vars */
import React, { useContext, ReactElement, ReactNode } from "react";

import type { Message } from "contexts/ChatProvider";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { map, omit, isEmpty } from "lodash";

import Avatar from "components/Avatar";
import { ChatContext } from "contexts/ChatProvider";
import { linkify } from "utils";

import ButtonQuestion from "./button-question";
import FormQuestion from "./form-question";
import FormAnswers, { FormAnswersProps } from "./form-answers";
import FileType from "./file-type/FileType";

interface MessageBoxProps {
  isSender?: boolean;
  isAgent?: boolean;
  isLast?: boolean;
  children: string | ReactNode;
  user?: string;
  datetime?: Date;
  bot?: Message["bot"];
  attachments?: Message["attachments"];
  type: Message["type"];
}

type MessageTypeProps = Omit<MessageBoxProps, "user" | "datetime">;

const Bubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSender",
})<{ isSender?: boolean }>(({ theme, isSender }) => ({
  padding: theme.spacing(1),
  backgroundColor: isSender
    ? theme.palette.secondary.main
    : theme.palette.neutral.extraLight,
  borderRadius: isSender
    ? theme.spacing(1.25, 0, 1.25, 1.25)
    : theme.spacing(0, 1.25, 1.25),
  ...(isSender && {
    color: theme.palette.common.white,
  }),
  "& a": {
    color: isSender ? theme.palette.common.white : theme.palette.info.main,
  },
  whiteSpace: "pre-wrap",
  maxWidth: "400px",
  wordBreak: "break-word",
}));

const MessageType = (props: MessageTypeProps): ReactElement | null => {
  const { type, attachments, isSender, children } = props;
  const direction = isSender ? "row-reverse" : "row";
  const realType = attachments && attachments.length > 0 ? "ATTACHMENT" : type;

  const renderBubble = (): ReactElement | null => {
    if (!children) return null;

    return (
      <Box display="flex" flexDirection={direction}>
        {typeof children === "string" ? (
          <Bubble
            display="flex"
            flexDirection="column"
            gap={1}
            isSender={isSender}
            dangerouslySetInnerHTML={{ __html: linkify(children) }}
          />
        ) : (
          <Bubble
            display="flex"
            flexDirection="column"
            gap={1}
            isSender={isSender}
          >
            {children}
          </Bubble>
        )}
      </Box>
    );
  };

  switch (realType) {
    case "FORM": {
      const answers = JSON.parse(children as string);
      const answerValues = Object.values(
        answers
      ) as FormAnswersProps["answerValues"];
      return <FormAnswers answerValues={answerValues} />;
    }
    case "ATTACHMENT":
      return (
        <>
          {attachments && attachments.length > 0 && (
            <>
              {map(attachments, (attachment) => (
                <FileType key={crypto.randomUUID()} {...attachment} />
              ))}
            </>
          )}
          {children && renderBubble()}
        </>
      );
    default:
      return renderBubble();
  }
};

const MessageBox = (props: MessageBoxProps): ReactElement => {
  const { isSender, children, datetime, bot, isLast, isAgent } = props;
  const { state } = useContext(ChatContext);
  const direction = isSender ? "row-reverse" : "row";
  const textAlign = isSender ? "end" : "start";
  const formControls = bot?.response?.formControls || [];
  const formControlType = bot?.response?.formControlType;
  const hasFormControl = !isSender && formControls.length > 0;
  const isBot = !isEmpty(bot);

  return (
    <Stack>
      <Box display="flex" flexDirection={direction}>
        {!isSender && !isBot && isAgent && (
          <Avatar
            isActive={state.socket.connected}
            firstName={"Agent"}
            lastName={""}
          />
        )}
        <Stack spacing={0.5}>
          {hasFormControl && formControlType === "FORM" ? (
            <FormQuestion
              message={children as string}
              controls={formControls}
              isLast={isLast}
            />
          ) : (
            <MessageType {...omit(props, "user", "dateTime", "isAgent")} />
          )}
          <Typography
            variant="body3"
            color="neutral.light"
            textAlign={textAlign}
          >
            {datetime ? moment(datetime).calendar() : moment().calendar()}
          </Typography>
          {hasFormControl && formControlType === "BUTTON_GROUP" && (
            <ButtonQuestion
              type={formControlType}
              controls={formControls}
              isLast={isLast}
            />
          )}
        </Stack>
      </Box>
      {hasFormControl && formControlType === "BUTTON_GROUP_VERTICAL" && (
        <ButtonQuestion
          type={formControlType}
          controls={formControls}
          isLast={isLast}
        />
      )}
    </Stack>
  );
};

MessageBox.defaultProps = {
  firstName: "",
  lastName: "",
  datetime: undefined,
  isActive: true,
};

export default MessageBox;

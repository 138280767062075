import type { Geocode } from "./types";

import axios, { AxiosResponse } from "axios";

export const getGeocode = (latlng: string): Promise<AxiosResponse<Geocode>> => {
  const result = axios.get(
    "https://maps.googleapis.com/maps/api/geocode/json",
    {
      params: {
        latlng,
        key: process.env.REACT_APP_GOOGLE_KEY,
      },
    }
  );
  return result;
};

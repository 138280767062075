import type { ErrorResponse } from "apis/types";

const getApiErrorMsg = (error: ErrorResponse): string => {
  if (error.error) {
    switch (typeof error.error) {
      case "string":
        return error.error;
      case "object":
        return error.error.message;
      default:
        return "Something Went Wrong";
    }
  }
  if (error.message) return error.message;
  if (error.debug) return error.debug.message;

  return "Something Went Wrong";
};

export default getApiErrorMsg;

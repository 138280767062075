import { createContext, Dispatch, useReducer, useEffect } from "react";

import type { ConversationResponse } from "apis/conversations/types";

import { Manager, Socket } from "socket.io-client";
import { useLocalStorage } from "react-use";
import { map, concat, filter, omit } from "lodash";

import { useCreateConversation } from "react-query/mutations";
import { decrypt } from "helpers";

export const manager = new Manager(process.env.REACT_APP_API2_BASE_URL);

export type MessageType = "TEXT" | "FORM" | "ATTACHMENT";

export type FormControl = {
  type: "TEXT_FIELD" | "BUTTON" | "MOBILE" | "NUMBER" | "EMAIL" | "SELECT";
  text: string;
  goTo: string;
  values?: string[] | number[];
  isEnd?: boolean;
};

export type FormControlType = "BUTTON_GROUP" | "BUTTON_GROUP_VERTICAL" | "FORM";

export type Message = {
  isSender?: boolean;
  hasError?: boolean;
  isAgent?: boolean;
  room: string | number;
  user?: string | "SYSTEM";
  message: string;
  datetime: Date;
  type: MessageType;
  participantId: number;
  attachments?: {
    file?: File;
    filename: string;
    originalFilename?: string;
  }[];
  bot?: {
    request?: {
      goTo: string;
      agentType?: string;
      userId?: string;
      sessionToken: string;
    };
    response?: {
      goTo: string;
      uid: string;
      text: string;
      formControlType: FormControlType;
      formControls: FormControl[];
      default: boolean;
      manualEnabled: boolean;
    };
  };
};

export type ListenStatus = {
  status: boolean;
  isAgent: boolean;
  departmentName: "PRC" | "CARE";
};

export type ListenSessionStatus = {
  endChat: {
    status: boolean;
    isAgent?: boolean;
    endedBy?: "CLIENT" | "AGENT";
  };
  findingAgent: {
    status: boolean;
    isAgent?: boolean;
  };
};

export interface AnswerBody {
  [x: string]: string | number;
}

interface State {
  socket: Socket;
  messages: Message[];
  isActive: boolean;
  isEndChat: boolean;
  isFinding: boolean;
  isReconnecting: boolean;
  identity?: ConversationResponse;
  answerValues?: AnswerBody;
  goTo?: string;
  errorMessage?: string;
  departmentName?: "PRC" | "CARE";
  typing?: {
    status: boolean;
    participantId: number;
    room: string;
  } | null;
}

interface Action {
  type:
    | "SET_MESSAGE"
    | "SET_OLD_MESSAGES"
    | "SET_ACTIVE"
    | "SET_END_CHAT"
    | "SET_FINDING"
    | "SET_IDENTITY"
    | "SET_SESSION_STATUS"
    | "SET_ANSWERS"
    | "SET_TYPING";
  payload?: {
    message?: Message;
    sessionToken?: string;
    isAgent?: boolean;
  } & Partial<State>;
}

const initialState: State = {
  socket: manager.socket("/who", {}),
  messages: [],
  isActive: false,
  isEndChat: false,
  isFinding: false,
  isReconnecting: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_MESSAGE": {
      const newMessages = [
        ...state.messages,
        action.payload?.message as Message,
      ];

      return { ...state, messages: newMessages };
    }
    case "SET_OLD_MESSAGES": {
      const oldMessages = map(action.payload?.messages, (oldMessage, index) => {
        const noOfMessages = action.payload?.messages?.length || 0;
        let newOldMessage = oldMessage;

        if (
          index + 1 !== noOfMessages &&
          oldMessage.bot?.response?.formControlType === "FORM"
        )
          newOldMessage = omit(oldMessage, "bot");

        return {
          ...newOldMessage,
          message: decrypt(oldMessage.message),
        };
      });
      const filteredmessages = filter(
        oldMessages,
        (oldMessage) =>
          !!oldMessage.message ||
          !!(oldMessage.attachments && oldMessage.attachments.length > 0)
      ) as Message[];
      const messages = concat(filteredmessages, state.messages);
      return {
        ...state,
        messages,
      };
    }
    case "SET_ACTIVE":
      return { ...state, isActive: !!action.payload?.isActive };
    case "SET_END_CHAT":
      return { ...state, isEndChat: !!action.payload?.isEndChat };
    case "SET_FINDING": {
      const oldIdentity = state.identity;
      const newIdentity = {
        ...oldIdentity,
        isAgent: !!action.payload?.isAgent,
      };
      return {
        ...state,
        isFinding: !!action.payload?.isFinding,
        identity: newIdentity as ConversationResponse,
        departmentName: action.payload?.departmentName,
      };
    }
    case "SET_IDENTITY":
      return { ...state, identity: action.payload?.identity };
    case "SET_SESSION_STATUS":
      return {
        ...state,
        isEndChat: !!action.payload?.isEndChat,
        isFinding: !!action.payload?.isFinding,
      };
    case "SET_ANSWERS":
      return {
        ...state,
        answerValues: action.payload?.answerValues,
      };
    case "SET_TYPING": {
      const { identity } = state;
      const room = identity?.conversation.uid;
      const typing = action.payload?.typing;

      return {
        ...state,
        ...(typing &&
          room === typing?.room && { typing: action.payload?.typing }),
      };
    }
    default:
      return state;
  }
};

const ChatContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const ChatProvider: React.FC<{
  children: React.ReactNode;
  initialState: State;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { socket, isEndChat } = state;
  const [identity, setIdentity, removeIdentity] =
    useLocalStorage<ConversationResponse>("identity");
  const participantId = identity?.conversation.participantId;
  const room = identity?.conversation.uid;
  const { mutate } = useCreateConversation();

  /*
    Todo:
      Create Conversation on page load to generate user identity or persist identity.
  */
  useEffect(() => {
    if ("userAgent" in navigator) {
      const params = new URL(window.location.href);
      const { userAgent } = navigator;
      const { searchParams } = params;
      const origin = searchParams.get("origin") || "WHO Live Chat";
      const originUrl = searchParams.get("originUrl") || window.location.href;
      const values = {
        userAgent,
        origin,
        originUrl,
      };

      if (isEndChat) return;

      if (!identity)
        mutate(values, {
          onSuccess: (data) => {
            setIdentity(data);
            dispatch({
              type: "SET_IDENTITY",
              payload: { identity: { ...data, isAgent: false } },
            });
            socket.emit("join_room", data.conversation.uid);
            socket.emit("send_message", {
              room: data.conversation.uid,
              isAgent: false,
              participantId: data.conversation.participantId,
              message: "",
              datetime: new Date(),
              type: "TEXT" as MessageType,
            });
          },
        });
      else {
        dispatch({
          type: "SET_IDENTITY",
          payload: { identity },
        });
        socket.emit("join_room", identity.conversation.uid);
      }
    }
  }, [mutate, setIdentity, identity, socket, isEndChat]);

  /* Todo: Get live messages */
  useEffect(() => {
    socket.on("receive_message", (data: Message) => {
      if (data.participantId === participantId) return;
      const message = { ...data, message: decrypt(data.message) };
      dispatch({
        type: "SET_MESSAGE",
        payload: { message },
      });
    });
    return () => {
      socket.off("receive_message");
    };
  }, [socket, participantId]);

  /* Todo: Catch if status is finding Agent */
  useEffect(() => {
    socket.on("finding_agent", (data: ListenStatus) => {
      dispatch({
        type: "SET_FINDING",
        payload: {
          isFinding: data.status,
          isAgent: data.isAgent,
          departmentName: data.departmentName,
        },
      });
      if (identity) {
        setIdentity({
          ...identity,
          isAgent: data.isAgent,
        } as ConversationResponse);
      }
    });
    return () => {
      socket.off("finding_agent");
    };
  }, [setIdentity, identity, socket]);

  /* Todo: Catch if session status in ended */
  useEffect(() => {
    socket.on("end_session", (data: ListenStatus) => {
      dispatch({
        type: "SET_END_CHAT",
        payload: { isEndChat: data.status },
      });
      removeIdentity();
    });
    return () => {
      socket.off("end_session");
    };
  }, [socket, removeIdentity]);

  /* Todo: Get old messages */
  useEffect(() => {
    if (room) {
      socket.emit("request_get_message", { room });
      socket.emit("request_session_status", { room });

      socket.on("get_message", (data: { messages: Message[] }) => {
        dispatch({
          type: "SET_OLD_MESSAGES",
          payload: {
            messages: data.messages,
          },
        });
      });
      socket.on("session_status", (data: ListenSessionStatus) => {
        dispatch({
          type: "SET_SESSION_STATUS",
          payload: {
            isEndChat: data.endChat?.status,
            isFinding: data.findingAgent.status,
          },
        });
      });
    }
    return () => {
      socket.off("get_message");
      socket.off("session_status");
    };
  }, [socket, room]);

  /* TODO: Listen Typing */
  useEffect(() => {
    socket.on("receive_typing", (data) => {
      dispatch({
        type: "SET_TYPING",
        payload: {
          typing: data,
        },
      });
    });
  }, [socket]);

  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
export { initialState };
export { ChatContext };

import { map, reduce, isEmpty } from "lodash";

import { yup } from "utils";

export const defaultValues = {
  message: "",
  fileUpload: [],
};

export const schema = yup.object().shape({
  message: yup.string(),
  fileUpload: yup
    .mixed()
    .test(
      "fileSize",
      "The file is too large. Maximum of 10 MB only.",
      (values) => {
        const mb = 10;
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        const megabyteSize = megabyte * mb;
        const sizes = map(values, "size");
        const totalFileSize = reduce(sizes, (acc, item) => acc + item);

        return isEmpty(values) || totalFileSize <= megabyteSize;
      }
    ),
});

import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, alpha } from "@mui/material/styles";
import { map } from "lodash";

export interface FormAnswersProps {
  answerValues: {
    value: string | number;
    description: string;
  }[];
}

const AnswerBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  borderRadius: 3,
  border: `1px solid ${alpha(theme.palette.neutral.light, 0.5)}`,
  backgroundColor: theme.palette.common.white,
  height: "2.4375em",
}));

const FormAnswers = (props: FormAnswersProps): React.ReactElement => {
  const { answerValues } = props;

  return (
    <Box
      sx={{ bgcolor: "neutral.extraLight" }}
      p={2}
      borderRadius={3}
      width={310}
    >
      <Stack spacing={1}>
        {map(answerValues, ({ description, value }) => (
          <Stack spacing={0.5} key={crypto.randomUUID()}>
            <Typography variant="body2" fontWeight="fontWeightMedium">
              {description}
            </Typography>
            <AnswerBox>
              <Typography variant="body2" fontWeight="fontWeightMedium">
                {description === "Contact Number" && (
                  <Typography
                    variant="body2"
                    fontWeight="fontWeightMedium"
                    component="span"
                    color="neutral.light"
                  >
                    +63{" "}
                  </Typography>
                )}
                {value}
              </Typography>
            </AnswerBox>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default FormAnswers;

import MuiAvatar, {
  AvatarProps as BaseAvatarProps,
} from "@mui/material/Avatar";
import MuiBadge, { badgeClasses } from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { stringAvatar } from "helpers";

interface AvatarProps extends BaseAvatarProps {
  firstName?: string;
  lastName?: string;
  isActive?: boolean;
  width?: number;
  height?: number;
}

const Badge = styled(MuiBadge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 8,
    height: 12,
    width: 12,
  },
  [`& .${badgeClasses.badge}.${badgeClasses.colorSuccess}`]: {
    backgroundColor: "#5FDD9D",
  },
}));

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "height",
})<AvatarProps>(({ theme, width, height }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightMedium,
  width,
  height,
}));

const Avatar = (props: AvatarProps): React.ReactElement => {
  const { isActive, src, width, height, firstName = "", lastName = "" } = props;
  const fullname = `${firstName} ${lastName}`;

  return (
    <Box>
      <Badge
        color={isActive ? "success" : "error"}
        overlap="circular"
        badgeContent=" "
        variant="dot"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ marginRight: 1 }}
      >
        <Tooltip title={fullname} placement="bottom-start" arrow>
          {src ? (
            <StyledAvatar
              alt={fullname}
              src={src}
              width={width}
              height={height}
            />
          ) : (
            <StyledAvatar
              width={width}
              height={height}
              sx={{ bgcolor: "primary.main" }}
            >
              {fullname && stringAvatar(firstName, lastName)}
            </StyledAvatar>
          )}
        </Tooltip>
      </Badge>
    </Box>
  );
};

Avatar.defaultProps = {
  firstName: "",
  lastName: "",
  isActive: true,
  width: 40,
  height: 40,
};

export default Avatar;

import type { TextFieldProps } from "./types";

import MuiTextField from "@mui/material/TextField";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useController } from "react-hook-form";
import { styled } from "@mui/material/styles";

import { useMetaError } from "hooks";

const ErroTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}));

const TextField = (props: TextFieldProps): React.ReactElement => {
  const { name, defaultValue, type, ...rest } = props;
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({ name, defaultValue });
  const { hasError, errorMessage } = useMetaError(fieldState);

  return (
    <ErroTooltip
      title={errorMessage || ""}
      arrow
      placement="right"
      disableHoverListener={!hasError}
      disableFocusListener={!hasError}
      disableInteractive={!hasError}
      disableTouchListener={!hasError}
    >
      <MuiTextField
        {...field}
        {...rest}
        inputRef={ref}
        id={name}
        error={hasError}
        // helperText={hasError ? errorMessage : helperText}
        type={type}
        sx={{
          '& input[type="time"]': {
            paddingRight: "6px",
          },
          '& input[type="time"]::-webkit-calendar-picker-indicator': {
            filter: "invert(50%)",
            fontSize: "24px",
          },
        }}
      />
    </ErroTooltip>
  );
};

TextField.defaultProps = {
  type: "text",
  label: "",
  name: "",
  placeholder: "Please Input",
  helperText: "",
  defaultValue: "",
};

export default TextField;

import { styled, Theme } from "@mui/material/styles";
import MuiTypography, {
  TypographyProps as BaseTypographyProps,
} from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

interface TypographyProps extends BaseTypographyProps {
  theme?: Theme;
  component?: string;
  fontSize?: number;
  isLoading?: boolean;
  loaderWidth?: number | string;
  fontWeight?:
    | "fontWeightRegular"
    | "fontWeightLight"
    | "fontWeightMedium"
    | "fontWeightBold"
    | string;
}

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: (props) => props !== "fontSize" && props !== "fontWeight",
})<TypographyProps>(({ theme, fontSize, fontWeight }) => ({
  ...(fontSize && { fontSize: theme.typography.pxToRem(fontSize) }),
  ...(fontWeight && {
    // @ts-ignore
    fontWeight: theme.typography[fontWeight],
  }),
}));

const Typography = (props: TypographyProps): React.ReactElement => {
  const { isLoading, loaderWidth, children, ...rest } = props;
  return (
    <StyledTypography {...rest}>
      {isLoading ? <Skeleton variant="text" width={loaderWidth} /> : children}
    </StyledTypography>
  );
};

Typography.defaultProps = {
  isLoading: false,
  loaderWidth: "auto",
};

export default Typography;

const getFileSize = (bytes: number, decimals: number = 0): string => {
  if (bytes === 0) return "0 Bytes";
  const kilobyte = 1024;
  const decimal = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(kilobyte));
  return `${parseFloat((bytes / kilobyte ** sizeIndex).toFixed(decimal))} ${
    sizes[sizeIndex]
  }`;
};

export default getFileSize;

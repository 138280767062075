import { replace } from "lodash";

const formatMobile = (value: string | undefined): string => {
  if (!value) return "";
  let phone = replace(value, /\D/g, "");
  if (phone.charAt(0) === "0") phone = phone.substring(1);

  const match = phone.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `+63 ${match[1]} ${match[2]} ${match[3]}`;
  }
  return phone;
};

export default formatMobile;

import type { FormProps } from "./types";

import { FormProvider, UseFormReturn } from "react-hook-form";

import TextField from "./TextField";
import Select from "./Select";

// type FormProps<TFormValues> = {
//   onSubmit: SubmitHandler<TFormValues>;
//   children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
// };

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  ...methods
}: FormProps<TFormValues> & UseFormReturn<TFormValues>): React.ReactElement => {
  return (
    <FormProvider {...methods}>
      {onSubmit ? (
        <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
      ) : (
        <form>{children}</form>
      )}
    </FormProvider>
  );
};

Form.TextField = TextField;
Form.Select = Select;

export default Form;

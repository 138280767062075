/* eslint-disable unused-imports/no-unused-vars */
import React, { useContext, useEffect, useCallback } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import MuiInputBase, { inputBaseClasses } from "@mui/material/InputBase";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InputAdornment from "@mui/material/InputAdornment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import EmojiPicker, { Categories, EmojiClickData } from "emoji-picker-react";
import { styled } from "@mui/material/styles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { Popover } from "@mui/material";
import { last, filter, isEmpty } from "lodash";
import { useToggle, useIdle } from "react-use";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Typography from "components/Typography";
import { ChatContext, MessageType } from "contexts";

import ChatBody from "./ChatBody";
import ChatContainer from "./ChatContainer";
import EndChat from "./EndChat";
import { defaultValues, schema } from "./utils";

type Inputs = {
  message: string;
  fileUpload?: FileList | never[];
};

const ChatHeader = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1, 1, 0, 0),
  backgroundImage:
    "linear-gradient(90deg, rgba(0, 193, 230, 1) 0%, rgba(29, 143, 191, 1) 100%)",
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const ChatContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  height: "100%",
}));

const ChatAction = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  position: "relative",
}));

const InputBase = styled(MuiInputBase)(() => ({
  borderColor: "#F5F5FF",
  [`& input.${inputBaseClasses.input}::placeholder`]: {
    color: "#DADADA",
  },
}));

const FileContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.neutral.extraLight}`,
  display: "flex",
  maxWidth: 500,
  maxHeight: 150,
  position: "absolute",
  background: theme.palette.common.white,
  bottom: 60,
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  flexWrap: "wrap",
}));

const FileRemoveButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  position: "absolute",
  right: -9,
  top: -9,
  height: "18px",
  width: "18px",
  "&:hover": {
    backgroundColor: theme.palette.neutral.semiLight,
  },
  "& svg": {
    color: theme.palette.common.white,
  },
}));

const InputFile = styled("input")(() => ({
  display: "none",
}));

const Chat = (): React.ReactElement => {
  const SPACES_URL = process.env.REACT_APP_SPACES_URL;
  const MINUTE = 60e3;
  const MINUTES = MINUTE * 20; // 20 MINUTES idle
  const {
    state: { socket, identity, isEndChat, messages, isFinding, typing },
    dispatch,
  } = useContext(ChatContext);
  const [showEndChat, toggleEndChat] = useToggle(false);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "emoji-menu",
  });
  const isIdle = useIdle(MINUTES);
  const lastMessage = last(filter(messages, (msg) => !msg.isSender));
  const participantId = identity?.conversation.participantId as number;
  const room = identity?.conversation.uid as string;
  const hasLiveMessages = messages.length > 0;
  const isAgent = !!identity?.isAgent;
  const isManual =
    isAgent ||
    !hasLiveMessages ||
    (messages.length > 0 && lastMessage?.bot?.response?.manualEnabled);
  const sessionToken = identity?.chatSessionToken as string;
  const imageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const message = watch("message") || "";
  const files = watch("fileUpload");
  const hasError = !isEmpty(errors);
  let typingTimeout: NodeJS.Timeout;

  const onSubmitMessage: SubmitHandler<Inputs> = async (data) => {
    const manualEnabled = !!lastMessage?.bot?.response?.manualEnabled;

    if (!identity) return;
    if (!data.message && (!data.fileUpload || data.fileUpload.length < 1))
      return;
    if (isEndChat) return;

    const type =
      data.fileUpload && data.fileUpload.length > 0
        ? "ATTACHMENT"
        : ("TEXT" as MessageType);
    const messageData = {
      room,
      isAgent,
      participantId,
      message: data.message,
      datetime: new Date(),
      type,
      ...(manualEnabled && {
        bot: {
          request: {
            goTo: lastMessage?.bot?.response?.goTo as string,
            sessionToken,
          },
        },
      }),
      ...(data.fileUpload &&
        data.fileUpload?.length > 0 && {
          attachments: [
            {
              file: data?.fileUpload[0],
              originalFilename: data?.fileUpload[0]?.name,
              filename: `${SPACES_URL}/whophchat/livechat/attachments/${data?.fileUpload[0]?.name}`,
            },
          ],
        }),
    };

    try {
      await socket.emit("send_message", messageData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      dispatch({
        type: "SET_MESSAGE",
        payload: { message: { ...messageData } },
      });
      reset(defaultValues);
      setFocus("message");
    }
  };

  const onEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      if (emojiData.emoji) setValue("message", `${message}${emojiData.emoji}`);
    },
    [setValue, message]
  );

  const handleTyping = (): void => {
    if (!room) return;

    if (!typing?.status) {
      socket?.emit("request_typing", {
        status: true,
        participantId,
        room,
      });
    }

    clearTimeout(typingTimeout);

    typingTimeout = setTimeout(() => {
      socket?.emit("request_typing", {
        status: false,
        participantId,
        room,
      });
    }, 2000);
  };

  useEffect(() => {
    if (!isIdle) return;
    if (!isAgent) return;
    if (isEndChat) return;

    socket.emit("request_end_session", {
      isAgent,
      room,
      isEndType: "idle",
    });
  }, [isIdle, socket, isAgent, room, isEndChat]);

  return (
    <ChatContainer>
      <ChatHeader>
        <Box>
          <Typography variant="h5" fontWeight="fontWeightBold" fontSize={20}>
            Welcome to Live Chat
          </Typography>
          <Typography variant="body1" fontWeight="fontWeightMedium">
            Here’s a few quick ways to connect with us.
          </Typography>
        </Box>
        {isAgent && !isEndChat && (
          <IconButton
            sx={{ height: "24px", width: "24px" }}
            onClick={() => toggleEndChat()}
          >
            <CloseIcon fontSize="inherit" sx={{ color: "common.white" }} />
          </IconButton>
        )}
      </ChatHeader>
      <ChatContent>
        <ChatBody>
          <EndChat show={showEndChat} toogleShow={toggleEndChat} />
        </ChatBody>
        <ChatAction>
          {files && files.length > 0 && (
            <FileContainer>
              {imageTypes.includes(files[0].type) ? (
                <Box minHeight={70} maxWidth={80} position="relative">
                  <CardMedia
                    component="img"
                    sx={{ borderRadius: 3 }}
                    alt={files[0].name}
                    image={URL.createObjectURL(files[0])}
                  />
                  <FileRemoveButton
                    size="small"
                    onClick={() => {
                      setValue("fileUpload", []);
                      clearErrors();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </FileRemoveButton>
                </Box>
              ) : (
                <Box maxWidth={200} display="flex" gap={1} whiteSpace="nowrap">
                  <InsertDriveFileIcon />
                  <Typography textOverflow="ellipsis" overflow="hidden">
                    {files[0].name}
                  </Typography>
                  <FileRemoveButton
                    size="small"
                    onClick={() => {
                      setValue("fileUpload", []);
                      clearErrors();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </FileRemoveButton>
                </Box>
              )}
            </FileContainer>
          )}
          <form onSubmit={handleSubmit(onSubmitMessage)}>
            <InputFile
              {...register("fileUpload")}
              type="file"
              id="fileUpload"
            />
            <InputBase
              {...register("message")}
              error={hasError}
              fullWidth
              placeholder="Type your reply here"
              startAdornment={
                <Tooltip
                  open={!!errors.fileUpload && files && files.length > 0}
                  title={`${errors.fileUpload?.message || ""}`}
                  placement="top"
                >
                  <InputAdornment position="start">
                    <IconButton
                      disabled={!isManual || isEndChat || !isAgent || isFinding}
                      onClick={() =>
                        document?.getElementById("fileUpload")?.click()
                      }
                    >
                      <AttachFileIcon
                        fontSize="inherit"
                        sx={{ transform: "rotate(220deg) scaleX(-1)" }}
                      />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    {...bindTrigger(popupState)}
                    disabled={!isManual || isEndChat || isFinding}
                  >
                    <SentimentSatisfiedOutlinedIcon fontSize="inherit" />
                  </IconButton>
                  <Popover
                    PaperProps={{
                      sx: {
                        borderRadius: 3,
                      },
                    }}
                    {...bindMenu(popupState)}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <EmojiPicker
                      autoFocusSearch={false}
                      lazyLoadEmojis={true}
                      height={400}
                      categories={[
                        {
                          category: Categories.SUGGESTED,
                          name: "Recently Used",
                        },
                        {
                          category: Categories.SMILEYS_PEOPLE,
                          name: "Smileys",
                        },
                      ]}
                      onEmojiClick={onEmojiClick}
                    />
                  </Popover>
                  <IconButton
                    onClick={handleSubmit(onSubmitMessage)}
                    disabled={!isManual || isEndChat}
                  >
                    <SendIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              }
              disabled={!isManual || isEndChat || isFinding}
              onKeyDown={handleTyping}
            />
          </form>
        </ChatAction>
      </ChatContent>
    </ChatContainer>
  );
};

export default Chat;

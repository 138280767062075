import { useContext } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";

import Typography from "components/Typography";
import { ChatContext } from "contexts";

const EndChat = (props: {
  show: boolean;
  toogleShow: (boolean) => void;
}): React.ReactElement => {
  const { show, toogleShow } = props;
  const {
    state: { socket, identity },
  } = useContext(ChatContext);

  const handleEndChat = async (): Promise<void> => {
    const isAgent = !!identity?.isAgent;
    const room = identity?.conversation.uid;

    try {
      await socket.emit("request_end_session", {
        isAgent,
        room,
        isEndType: "end",
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      toogleShow(false);
    }
  };

  return (
    <Fade in={show}>
      <Stack
        alignItems="center"
        justifyContent="end"
        height="100%"
        position="absolute"
        right={0}
        top={0}
        bgcolor="common.white"
        width="100%"
        zIndex={2}
        mb={10}
      >
        <Stack spacing={15} maxWidth={339} px={1} mb={5}>
          <Stack spacing={3} alignItems="center">
            <Typography
              color="primary"
              variant="h5"
              fontWeight="fontWeightBold"
            >
              End Chat?
            </Typography>
            <Typography
              variant="body2"
              textAlign="center"
              fontWeight="fontWeightMedium"
            >
              Gusto mo pa bang magpatuloy sa ating Chat App? Piliin ang inyong
              sagot sa ibaba
            </Typography>
          </Stack>
          <Stack spacing={1} width="100%">
            <Button
              variant="outlined"
              sx={{
                borderRadius: 3,
              }}
              fullWidth
              onClick={handleEndChat}
            >
              Hindi na
            </Button>
            <Button
              sx={{
                borderRadius: 3,
              }}
              variant="outlined"
              fullWidth
              onClick={() => toogleShow(false)}
            >
              Bumalik at magpatuloy
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Fade>
  );
};

export default EndChat;

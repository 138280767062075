import type {
  ConversationBody,
  ConversationResponse,
} from "apis/conversations/types";
import type { ErrorResponse } from "apis/types";

import { useMutation, UseMutationResult } from "react-query";

import { createConversation } from "apis";

export const useCreateConversation = (): UseMutationResult<
  ConversationResponse,
  ErrorResponse,
  ConversationBody
> => useMutation(createConversation);

import { useContext } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { map, camelCase, find, reduce, filter } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "components/Form";
import { yup } from "utils";
import {
  ChatContext,
  MessageType,
  FormControl,
  AnswerBody,
} from "contexts/ChatProvider";

import InputTypes from "./input-types";

interface FormQuestionProps {
  message: string;
  controls: FormControl[];
  isLast?: boolean;
}

const FormQuestion = (props: FormQuestionProps): React.ReactElement => {
  const { controls, isLast, message } = props;
  const namedControls = map(controls, (control) => ({
    ...control,
    name: camelCase(control.text),
  }));
  const {
    state: { socket, identity, answerValues },
    dispatch,
  } = useContext(ChatContext);
  const controlInputs = filter(controls, ({ type }) => type !== "BUTTON");
  const initialValues =
    answerValues ||
    reduce(
      controlInputs,
      (acc, { text }) => ({ ...acc, [camelCase(text)]: "" }),
      {}
    );
  const validation = reduce(
    controlInputs,
    (acc, { text, type }) => {
      const controlName = camelCase(text);
      let fields = {};
      switch (type) {
        case "TEXT_FIELD":
        case "SELECT":
          fields = { [controlName]: yup.string() };
          break;
        case "NUMBER":
          fields =
            controlName === "age"
              ? {
                  [controlName]: yup.lazy((value) =>
                    value
                      ? yup
                          .number()
                          .typeError("This field is required")
                          .min(15, "Age should be at least 15")
                          .max(99)
                      : yup.string()
                  ),
                }
              : {
                  [controlName]: yup.lazy((value) =>
                    value
                      ? yup.number().typeError("This field is required")
                      : yup.string()
                  ),
                };
          break;
        case "EMAIL":
          fields = { [controlName]: yup.string().email() };
          break;
        case "MOBILE":
          fields = { [controlName]: yup.string().phone() };
          break;
        default:
          fields = {};
          break;
      }
      return { ...acc, ...fields };
    },
    {}
  );
  const methods = useForm<AnswerBody>({
    shouldUnregister: false,
    defaultValues: initialValues,
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const participantId = identity?.conversation.participantId as number;
  const room = identity?.conversation.uid as string;
  const sessionToken = identity?.chatSessionToken as string;

  const handleSubmit = async (values: AnswerBody): Promise<void> => {
    const newvalues = reduce(
      values,
      (result, value, key) => ({
        ...result,
        ...{
          [key]: {
            value,
            description: find(namedControls, (control) => control.name === key)
              ?.text,
          },
        },
      }),
      {}
    );
    const goTo = find(map(controls, "goTo"), (data) => !!data);
    const messageData = {
      room,
      isAgent: false,
      participantId,
      datetime: new Date(),
      type: "FORM" as MessageType,
      bot: {
        request: {
          goTo,
          sessionToken,
        },
      },
      message: JSON.stringify(newvalues),
    };

    if (!identity) return;

    try {
      await socket.emit("send_message", messageData);
      dispatch({ type: "SET_ANSWERS", payload: { answerValues: values } });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <Box
      sx={{ bgcolor: "neutral.extraLight" }}
      p={2}
      borderRadius={3}
      width={310}
    >
      <Form<AnswerBody> {...methods}>
        <Stack spacing={1}>
          <Typography variant="body1" fontWeight="fontWeightMedium">
            {message}.
          </Typography>
          {map(namedControls, ({ name, ...control }) => {
            if (control.type === "BUTTON")
              return (
                <Button
                  key={crypto.randomUUID()}
                  type="button"
                  disabled={isLast}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  {control.text}
                </Button>
              );
            return (
              <InputTypes key={crypto.randomUUID()} name={name} {...control} />
            );
          })}
        </Stack>
      </Form>
    </Box>
  );
};

export default FormQuestion;
